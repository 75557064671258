// @import '~antd/dist/antd.dark.less';
@import '~@ant-design/pro-form/dist/form.less';
@import '~@ant-design/pro-layout/dist/layout.less';
@import '~@ant-design/pro-table/dist/table.less';
@import '~@ant-design/pro-list/dist/list.less';
@import '~@ant-design/pro-card/dist/card.less';
/* @import '~antd/dist/antd.dark.css'; */
@import '~antd/dist/antd.dark.less';

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2a8195;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-track,
.ant-result ant-result-404 {
  background: #131313;
}
#unauthorized {
  background: #141414;
}

*::-webkit-scrollbar-thumb {
  background-color: #2a8195;
  border-radius: 20px;
  border: 3px solid #131313;
}
.ant-btn-primary {
  background: linear-gradient(90deg, #2a8195, #33d6fb);
}

@primary-color: #2a8195;